<template>
    <div class="form-page">
        <p class="success">
            {{ $t('enrollment.application_successful') }}
        </p>
        {{ $t('enrollment.application_reg_number') }} <strong class="nowrap">{{ reg_number }}</strong>
        
        <a :href="pdf_url" target="_blank">
            <BaseButton class="btn-success print-application">
                Vytisknout žádost
            </BaseButton>
        </a>
        
        

        <Block class="pokyny" :content="getLayoutContent('pokyny')" />
        
        <div class="buttons">      
            <BaseButton class="" @click="$emit('closeForm')">
                Zavřít formulář
            </BaseButton> 
            <BaseButton class="btn-primary" @click="$emit('reset')">
                Zadat další žádost
            </BaseButton>
        </div>
        
        <!-- <teleport to="#app">
            <ModalPdf  :opened="pdf_url != ''" @close="pdf_url = ''" :pdf_url="pdf_url" />
        </teleport> -->
    </div>
</template>

<script>
import Block from "@common/components/cms_app/Block";
import { computed, ref, watchEffect } from 'vue';
import { useStore } from 'vuex';
import useContent from "@common/composable/cms_app/Content";
import { useRouter } from 'vue-router';
import config from 'config';

export default {
    name: "ApplicationFormPage3",
    components: {
        Block,
        // ModalPdf: defineAsyncComponent(() => import(/* webpackChunkName: "modal-pdf" */'@common/components/base/ModalPdf')),
    },
    emits: ['reset','closeForm'],
    setup(){
        const { getLayoutContent } = useContent();

        const store = useStore();

        const router = useRouter();

        const reg_number = computed(() => store.getters['application/getRegNumber']);
        const code = computed(() => store.getters['application/getCode']);

        const pdf_url = ref('');

        const generate_pdf = () => {
            let project = router.currentRoute.value.params.project_code;
            if(!project && config.project_key){
                project = config.project_key;
            }

            pdf_url.value = config.apiUrl+'/'+router.currentRoute.value.params.lang+`/`+project+`/v1/enrollment/report/generate-transcript?code=${code.value}`;
            console.log(pdf_url.value);
        }
        watchEffect(() => generate_pdf());

        return {
            reg_number,
            getLayoutContent,
            // generate_pdf,
            pdf_url
        }
    }
}
</script>

<style scoped>
.success{
    color: rgb(3, 124, 3);
}
.form-page ::v-deep(ul){
    
    list-style-type: square;
}
.buttons .btn{
    margin: 0.5rem;
}
.print-application{
    font-size: 1.5rem;
    display: block;
    margin: 0.5rem auto;
}
.nowrap{
    white-space: nowrap;
}
</style>